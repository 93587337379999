import axios from "axios";
import authHeader from "./auth-header";
import router from "../router";
import store from '../store';

const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
})

api.interceptors.response.use(
    response => response,
    async error => {
        if (error.response.status === 401) {
            store.dispatch("auth/logout");
            router.push('/login');
        }
        return Promise.reject(error);
    }
)

api.interceptors.request.use(
  config => {
    config.headers = {...config.headers, ...authHeader()};
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default api;