import api from "./axios";

export default {
    async getEndUsers(page=1) {
        const response = await api.get('/endUsers', { params: { page } });
        return response.data;
    },
    async addEndUser(endUser) {
        const response = await api.post('/endUsers', endUser)

        return response.data;
    },
    async updateEndUser(endUser) {
        const response = await api.put(`/endUsers/${endUser.id}`, endUser)

        return response.data;
    },
    async sendAccessEmail(id) {
        const response = await api.post(`/endUsers/${id}/accessEmail`)

        return response.data
    }
}