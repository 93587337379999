import api from "./axios";

export default {
    async getCompanies(page = 1) {
        const response = await api.get(`/companies?page=${page}`);
        return response.data;
    },
    async getOptions() {
        const response = await api.get("/companies/options");
        return response.data;
    },
    async addCompany(company) {
        const response = await api.post("/companies", company)

        return response.data;
    },
    async updateCompany(company) {
        const response = await api.put(`/companies/${company.id}`, company)

        return response.data;
    },
}