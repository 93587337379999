import api from "./axios";
import Jsona from "jsona";
const dataFormatter = new Jsona();

export default {
  async getProfile() {
    const response = await api.get("/me");
    return dataFormatter.deserialize(response.data);
  },

  async uploadPic(object) {
    let formData = new FormData();
    formData.append("attachment", object.pic);
    const response = await api.post(
      `/uploads/users/${object.id}/profile-image`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data.url;
  },

  async updateProfile(profile) {
    profile.type = "profile";
    delete profile.links;
    const response = await api.patch(
      "/me",
      dataFormatter.serialize({ stuff: profile }),
    );
    return dataFormatter.deserialize(response.data);
  },
};
