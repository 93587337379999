<template>
    <tr @click="startEditing">
        <td>{{ name }}</td>
        <td>{{ code }}</td>
        <td>{{ plan }}</td>
        <td>{{ single_use }}</td>
        <td>{{ active }}</td>
        <td>{{ created_at }}</td>
    </tr>
</template>

<script>
import moment from 'moment';

export default {
    props: ['voucher'],
    data: (instance) => ({
        name: instance.voucher.name,
        code: instance.voucher.code,
        single_use: instance.voucher.single_use ? "Sim" : "Não",
        plan: instance.voucher.plan_name,
        active: instance.voucher.active ? "Sim" : "Não",
        created_at: moment(instance.voucher.created_at).format("DD/MM/YYYY HH:mm")
    }),
    methods: {
        startEditing() {
            this.$store.dispatch('vouchers/startEditing', this.voucher)
        }
    }
}
</script>