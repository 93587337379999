<template>
    <tr @click="startEditing">
        <td>{{ id }}</td>
        <td>{{ name }}</td>
        <td>{{ created_at }}</td>
    </tr>
</template>

<script>
import moment from 'moment';

export default {
    props: ['oauthClient'],
    data: (instance) => ({
        id: instance.oauthClient.id,
        name: instance.oauthClient.name,
        created_at: moment(instance.oauthClient.created_at).format("DD/MM/YYYY HH:mm")
    }),
    methods: {
        startEditing() {
            this.$store.dispatch('oauthClients/startEditing', this.oauthClient)
        }
    }
}
</script>