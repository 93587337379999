<template>
  <div class="modal fade" id="voucher_modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <form
            id="myForm"
            @submit.prevent="handleSubmit"
          >
            <label for="name">Nome</label>
            <soft-model-input
              id="name"
              v-model="state.name"
              type="text"
              placeholder="Nome"
              name="name"
            />
            
            <label for="name">Código</label>
            <soft-model-input
              id="code"
              v-model="state.code"
              type="text"
              placeholder="Código"
              name="code"
            />
            
            <label for="discount_value">Valor de desconto</label>
            <soft-model-input
              id="discount_value"
              v-model="state.discount_value"
              placeholder="Valor de desconto"
              name="discount_value"
            />
            
            <label for="plan_id">Plano</label>
            <soft-select
              id="plan_id"
              name="plan_id"
              v-model="state.plan_id"
              :options="plans"
              placeholder="Selecione o plano"
            />
            
            <label for="name">Data de Expiração</label>
            <VueDatePicker 
              id="expire_at"
              v-model="state.expire_at"
              placeholder="Data de Expiração"
              name="expire_at"
              format="dd/MM/yyyy HH:mm"
              :model-type="modelType"
            />
            
            <soft-switch-model 
              id="single_use"
              name="single_use"
              v-model="state.single_use"
            >
              Uso único
            </soft-switch-model>
 
            <soft-switch-model 
              id="per_user"
              name="per_user"
              v-model="state.per_user"
            >
              Uso único por pessoa
            </soft-switch-model>

            <soft-switch-model 
              id="active"
              name="active"
              v-model="state.active"
            >
              Ativo
            </soft-switch-model>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="stopEditing">Cancelar</button>
          <button
            type="submit"
            form="myForm"
            class="btn btn-success"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import SoftModelInput from "../../components/SoftModelInput";
import SoftSwitchModel from "../../components/SoftSwitchModel.vue";
import SoftSelect from "../../components/SoftSelect.vue";

import * as yup from "yup";
import { mapState } from "vuex";
import formModalMixin from "../../mixins/form-modal-mixin";
import { floatToMoney } from "../../helpers/money";

const getState = (voucher = null) => ({
  id: voucher?.id,
  name: voucher?.name ?? '',
  code: voucher?.code ?? '',
  plan_id: voucher?.plan_id ?? '',
  expire_at: voucher?.expire_at ?? '',
  discount_value: voucher?.discount_value != null 
    ? floatToMoney(voucher.discount_value) : '',
  single_use: voucher?.single_use ?? false,
  per_user: voucher?.per_user ?? false,
  active: voucher?.active ?? true,
});

export default {
  name: "VoucherModal",
  mixins: [formModalMixin('vouchers')],
  components: {
    SoftSwitchModel,
    SoftSelect,
    SoftModelInput,
  },
  mounted() {
    this.voucher_modal = new bootstrap.Modal('#voucher_modal', {
      backdrop: 'static'
    });
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string().required(),
      code: yup.string().required(),
      plan_id: yup.number().required(),
      expire_at: yup.date().required(),
      discount_value: yup.number().required()
    });

    return {
      schema,
      loading: false,
      state: getState(),
      modelType: "yyyy-MM-dd HH:mm:ss"
    }
  },
  async created() {
    try {
      await this.$store.dispatch("plans/getOptions");
    } catch (error) {
      console.error(error);
    }
  },
  computed: {
    ...mapState('vouchers', {
      open: state => state.form.open,
      voucher: state => state.form.voucher,
      title: state => state.form.voucher?.name ?? "Novo Cupom",
    }),
    plans() {
      return this.$store.state.plans.options;
    }
  },
  watch: {
    open (newOpen) {
      if (newOpen) this.openVoucherModal();
      else this.closeVoucherModal();
    },
    voucher (newVoucher) {
      this.state = getState(newVoucher);
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      try {
        if (this.voucher) await this.$store.dispatch('vouchers/updateVoucher', this.state);
        else await this.$store.dispatch('vouchers/addVoucher', this.state);
        this.loading = false;
        this.state = getState();
      } catch (error) {
        this.loading = false;
      }
    },
    stopEditing() {
      this.$store.dispatch('vouchers/stopEditing');
    },
    openVoucherModal() {
      this.voucher_modal.show()
    },
    closeVoucherModal() {
      this.voucher_modal.hide()
    }
  },
}
</script>