<template>
  <div class="modal fade" id="oauthClient_modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <form
            id="myForm"
            @submit.prevent="handleSubmit"
          >
            <label for="name">Nome</label>
            <soft-model-input
              id="name"
              v-model="state.name"
              type="text"
              placeholder="Nome"
              name="name"
            />
            
            <label for="name">URL de Redirecionamento</label>
            <soft-model-input
              id="redirect"
              v-model="state.redirect"
              type="text"
              placeholder="URL de Redirecionamento"
              name="redirect"
            />
            
            <label for="name">ID do Cliente</label>
            <soft-model-input
              id="client_id"
              v-model="state.client_id"
              type="text"
              placeholder="ID do Cliente"
              name="client_id"
            />
            
            <label for="name">Segredo do Cliente</label>
            <soft-model-input
              id="client_secret"
              v-model="state.client_secret"
              type="text"
              placeholder="Segredo do Cliente"
              name="client_secret"
            />
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="stopEditing">Cancelar</button>
          <button
            type="submit"
            form="myForm"
            class="btn btn-success"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import SoftModelInput from "../../components/SoftModelInput";

import * as yup from "yup";
import showSwal from "../../mixins/showSwal";
// import moment from "moment";
import { mapState } from "vuex";
import formModalMixin from "../../mixins/form-modal-mixin";

const getState = (oauthClient = null) => ({
  id: oauthClient?.id,
  client_id: oauthClient?.id ?? '',
  name: oauthClient?.name ?? '',
  redirect: oauthClient?.redirect ?? '',
  client_secret: oauthClient?.secret ?? '',
});

export default {
  mixins: [formModalMixin("oauthClients")],
  name: "OauthClientModal",
  components: {
    SoftModelInput,
  },
  mounted() {
    this.oauthClient_modal = new bootstrap.Modal('#oauthClient_modal', {
      backdrop: 'static'
    });
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string().required(),
      redirect: yup.string().required(),
    });

    return {
      schema,
      loading: false,
      state: getState(),
    }
  },
  computed: {
    ...mapState('oauthClients', {
      open: state => state.form.open,
      oauthClient: state => state.form.oauthClient,
      title: state => state.form.oauthClient?.name ?? "Novo Cliente Oauth",
    }),
  },
  watch: {
    open (newOpen) {
      if (newOpen) this.openOauthClientModal();
      else this.closeOauthClientModal();
    },
    oauthClient (newOauthClient) {
      this.state = getState(newOauthClient);
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      try {
        if (this.oauthClient) 
        await this.$store.dispatch('oauthClients/updateOauthClient', this.state);
        else
        await this.$store.dispatch('oauthClients/addOauthClient', this.state);
          if(this.error == null){
            showSwal.methods.showSwal({
              type:"success",
              message: "Usuário Oauth Criado Com Sucesso!",
              timer: 5000
            })
          }
        this.loading = false;
        this.state = getState();
      } catch (error) {
        this.loading = false;
      }
    },
    stopEditing() {
      this.$store.dispatch('oauthClients/stopEditing');
    },
    openOauthClientModal() {
      this.oauthClient_modal.show()
    },
    closeOauthClientModal() {
      this.oauthClient_modal.hide()
    }
  },
}
</script>