import oauthService from "../services/oauth.service"
import { parseFormError } from "../services/utils";

const initialState = {
  oauthClients: [],
  loading: false,
  page: 1,
  lastPage: 1,
  perPage: 1,
  total: 0,
  form: {
    open: false,
    oauthClient: null,
    error: null
  }
}

export const oauth = {
  namespaced: true,
  state: initialState,
  actions: {
    async getOauthClients({ commit, state }) {
      commit("SET_LOADING", true);
      const response = await oauthService.getOauthClients(state.page);
      commit("SET_CLIENTS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);
    },
    changeToPage({ commit, dispatch, state }, page) {
      if (page >= 1 && page <= state.lastPage) {
        commit("SET_PAGE", page);
        dispatch("getOauthClients");
      }
    },
    startAdding({ commit }) {
      const value = { open: true, oauthClient: null };
      commit("SET_FORM", value);
    },
    async addOauthClient({ commit, dispatch }, oauthClient) {
      try {
        await oauthService.addOauthClient(oauthClient);
        dispatch("stopEditing");
        dispatch("getOauthClients");
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error))
      }
    },
    startEditing({ commit }, oauthClient) {
      const value = { open: true, oauthClient: oauthClient };
      commit("SET_FORM", value);
    },
    async updateOauthClient({ commit, dispatch }, oauthClient) {
      try {
        await oauthService.updateOauthClient(oauthClient);
        dispatch("stopEditing");
        dispatch("getOauthClients");
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error))
      }
    },
    stopEditing({ commit }) {
      const value = { open: false, oauthClient: null };
      commit("SET_FORM", value);
    },
  },
  mutations: {
    SET_CLIENTS(state, oauthClients) {
      state.oauthClients = oauthClients
      state.loading = false
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    SET_TOTAL(state, total) {
      state.total = total
    },
    SET_PER_PAGE(state, per_page) {
      state.perPage = per_page
    },
    SET_LAST_PAGE(state, last_page) {
      state.lastPage = last_page
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_FORM(state, { open, oauthClient }) {
      state.form.open = open;
      state.form.oauthClient = oauthClient;
      state.form.error = null;
    },
    SET_FORM_ERROR(state, error) {
      state.form.error = error
    },
    resetState(state) {
      state.oauthClients = [];
      state.loading = false;
      state.form = {
        open: false,
        oauthClient: null,
      };
    }
  },
}