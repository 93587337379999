<template>
    <tr @click="startEditing">
        <td>{{ name }}</td>
        <td>{{ email }}</td>
        <td>{{ active }}</td>
        <td>{{ company }}</td>
        <td>{{ created_at }}</td>
        <td class="d-flex gap-1">
            <button class="btn btn-primary" @click.stop="openPlansModal">Planos</button>
            <button 
                class="btn btn-secondary" 
                data-toggle="tooltip"
                data-placement="top"
                title="Enviar email de acesso"
                @click.stop="sendAccessEmail"
            >
                <span
                    v-if="sendingEmail"
                    class="spinner-border spinner-border-sm"
                ></span>
                <FontAwesomeIcon v-else :icon="faEnvelope" />
            </button>
        </td>
    </tr>
    <EndUserPlanModal :open="open" :endUserId="id" :onClose="closePlansModal"/>
</template>

<script>
import moment from 'moment';
import EndUserPlanModal from './EndUserPlanModal.vue';
import showSwal from "/src/mixins/showSwal.js";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
    name: "EndUserTile",
    props: ['endUser'],
    components: {
        EndUserPlanModal,
        FontAwesomeIcon
    },
    data: (instance) => ({
        open: false,
        sendingEmail: false,
        id: instance.endUser.id,
        name: instance.endUser.name,
        email: instance.endUser.email,
        active: instance.endUser.active ? "Sim" : "Não",
        company: instance.endUser.company_name,
        created_at: moment(instance.endUser.created_at).format("DD/MM/YYYY HH:mm"),
        faEnvelope
    }),
    methods: {
        startEditing() {
            this.$store.dispatch('endUsers/startEditing', this.endUser)
        },
        openPlansModal() {
            this.open = true
        },
        closePlansModal() {
            this.open = false
        },
        async sendAccessEmail() {
            this.sendingEmail = true
            try {
                await this.$store.dispatch('endUsers/sendAccessEmail', this.endUser)
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Email enviado",
                });
            } catch (error) {
                console.error(error) 
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Erro ao enviar email",
                });
            }
            this.sendingEmail = false
        },
    }
}
</script>