<template>
  <div class="modal fade" :id="modalId" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">Planos do Usuário</h5>
        </div>
        <div class="modal-body">
          <div class="spinner-border" role="status" v-if="loading">
            <span class="sr-only">Carregando...</span>
          </div>
          <table v-else id="endUsers-list" ref="endUsersList" class="table table-flush">
          <thead class="thead-light">
            <tr>
              <th>Plano</th>
              <th>Data de ativação</th>
              <th>Data de encerramento</th>
            </tr>
          </thead>
          <tbody class="text-sm">
            <end-user-plan-tile v-for="( plan, index ) in plans" :endUserPlan="plan" :key="index" />
          </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="onClose">Fechar</button>
          <button v-if="!readOnly" type="button" class="btn btn-secondary" @click="startAdding">Adicionar</button>
        </div>
      </div>
    </div>
  </div>

  <AddEndUserPlanModal :open="adding" :endUserId="endUserId" :onClose="stopAdding" />
</template>

<script>
import showSwal from "/src/mixins/showSwal.js";
import bootstrap from "bootstrap/dist/js/bootstrap";
import AddEndUserPlanModal from "./AddEndUserPlanModal.vue";
import endUserPlansService from "../../services/endUserPlans.service";
import EndUserPlanTile from "./EndUserPlanTile.vue";
import { mapState } from "vuex/dist/vuex.cjs.js";
import formModalMixin from "../../mixins/form-modal-mixin";


export default {
  name: "EndUserPlanModal",
  mixins:[formModalMixin ("endUserPlans")],
  props: ['endUserId', 'open', 'onClose'],
  components: {
    AddEndUserPlanModal,
    EndUserPlanTile,
  },
  mounted() {
    this.endUserPlan_modal = new bootstrap.Modal(`#${this.modalId}`, {
      backdrop: 'static'
    });
  },
  data() {
    return {
      adding: false,
      modalId: "endUserPlan_modal_" + this.endUserId,
      loading: false,
      plans: []
    }
  },
  watch: {
    open (newOpen) {
      if (newOpen) this.openEndUserModal();
      else this.closeEndUserModal();
    },
  },
  computed: {
    ...mapState('auth', {
      readOnly: state => state.readOnly
    })
  },
  methods: {
    startAdding() {
      this.adding = true;
    },
    stopAdding(update = false) {
      this.adding = false;
      if (update === true) this.getPlans();
    },
    openEndUserModal() {
      this.endUserPlan_modal.show()
      this.getPlans();
    },
    closeEndUserModal() {
      this.endUserPlan_modal.hide()
    },
    async getPlans() {
      this.loading = true;
      try {
        this.plans = 
        await endUserPlansService.getEndUserPlans(this.endUserId);
          if(this.error == null){
            showSwal.methods.showSwal({
            type:"success",
            message:"Plano Cadastrado Com Sucesso!",
            time: 5000
            })
          }
      } catch (error) {
        console.log(error); 
      }
      this.loading = false;
    },
    alert(msg) {
      showSwal.methods.showSwal({
        type: "error",
        message: msg,
        width: 400,
      });
    },
  },
};
</script>