<template>
  <div class="modal fade" id="endUser_modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <form @submit.prevent="handleSubmit">
            <label>Id Externo</label>
            <soft-model-input
              id="externalId"
              v-model="state.externalId"
              type="text"
              placeholder="Id Externo"
              name="externalId"
            />

            <label for="name">Nome</label>
            <soft-model-input
              id="name"
              v-model="state.name"
              type="text"
              placeholder="Nome"
              name="name"
            />
            
            <label for="name">Nome de Usuário</label>
            <soft-model-input
              id="username"
              v-model="state.username"
              type="username"
              placeholder="Nome de usuário"
              name="username"
            />

            <label for="email">E-mail</label>
            <soft-model-input
              id="email"
              v-model="state.email"
              type="email"
              placeholder="E-mail"
              name="email"
            />
            
            <label>CPF</label>
            <soft-model-input
              id="cpf"
              v-model="state.cpf"
              type="text"
              placeholder="CPF"
              name="cpf"
            />
            
            <label for="name">Data de Nascimento</label>
            <VueDatePicker 
              id="birthday"
              v-model="state.birthday"
              placeholder="Data de Nascimento"
              name="birthday"
              format="dd/MM/yyyy"
              :model-type="modelType"
              :enable-time-picker="false"
              :locale="$datePickerLocale"  
            />

            <label>Senha</label>
            <soft-model-input
              id="password"
              v-model="state.password"
              type="password"
              placeholder="Senha"
              name="password"
            />

            <div v-if="isAdmin">
              <label>Empresa</label>
              <soft-select
                id="company"
                name="company"
                v-model="state.company"
                :options="companies"
                placeholder="Selecione a empresa"
              />
            </div>

            <soft-switch-model 
              id="active"
              name="active"
              v-model="state.active"
            >
              Ativo
            </soft-switch-model>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="stopEditing">Cancelar</button>
          <button
            type="button"
            @click="handleSubmit"
            class="btn btn-success"
            :disabled="loading ? true : false"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import SoftModelInput from "@/components/SoftModelInput.vue";
import SoftSwitchModel from "../../components/SoftSwitchModel.vue";
import SoftSelect from "../../components/SoftSelect";
import formModalMixin from "../../mixins/form-modal-mixin";
import * as yup from "yup";
import { mapState } from "vuex";
import showSwal from "../../mixins/showSwal";


const getState = (endUser = null) => ({
  id: endUser?.id,
  name: endUser?.name ?? '',
  email: endUser?.email ?? '',
  company: endUser?.company ?? '',
  active: endUser?.active ?? true,
  cpf: endUser?.cpf ?? '',
  username: endUser?.username ?? '',
  birthday: endUser?.birthday ?? '',
})

export default {
  name: "EndUserModal",
  mixins: [formModalMixin("endUsers")],
  components: {
    SoftModelInput,
    SoftSwitchModel,
    SoftSelect
  },
  mounted() {
    this.endUser_modal = new bootstrap.Modal('#endUser_modal', {
      backdrop: 'static'
    });
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string().required(),
      email: yup
        .string()
        .required("Email is required!")
        .email("Must be a valid email! ex.: name@company.domain"),
      password: yup.string(),
      active: yup.boolean().required(),
      is_admin: yup.boolean().required(),
    });

    return {
      schema,
      loading: false,
      state: getState(),
      modelType: "yyyy-MM-dd"
    }
  },
  computed: {
    ...mapState('endUsers', {
      open: state => state.form.open,
      endUser: state => state.form.endUser,
      title: state => state.form.endUser?.name ?? "Novo Usuário",
    }),
    isAdmin() {
      return this.$store.getters['auth/isAdmin']
    },
    companies() {
      return this.$store.state.companies.options;
    }
  },
  async created() {
    try {
      if (this.isAdmin) {
        await this.$store.dispatch("companies/getOptions");
      }
    } catch (error) {
      console.error(error);
    }
  },
  watch: {
    open (newOpen) {
      if (newOpen) this.openEndUserModal();
      else this.closeEndUserModal();
    },
    endUser (newEndUser) {
      this.state = getState(newEndUser);
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      try {
        if (this.endUser) 
        await this.$store.dispatch('endUsers/updateEndUser', this.state);
        else
        await this.$store.dispatch('endUsers/addEndUser', this.state);
        if(this.error == null){
          showSwal.methods.showSwal({
            type: "success",
            message:"Usuário Cadastrado Com Sucesso!",
            timer: 5000
          })
        }
        this.loading = false
        this.state = getState();
      } catch (error) {
        this.loading = false;
      }
    },
    stopEditing() {
      this.$store.dispatch('endUsers/stopEditing');
    },
    openEndUserModal() {
      this.endUser_modal.show()
    },
    closeEndUserModal() {
      this.endUser_modal.hide()
    }
  },
}
</script>