<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Usuários</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <a @click="startAdding" class="mb-0 btn bg-gradient-success btn-sm">
                    +&nbsp; Novo Usuário
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="spinner-border" role="status" v-if="loading">
            <span class="sr-only">Carregando...</span>
          </div>
          <div class="px-0 pb-0 card-body" v-else>
            <div class="table-responsive">
              <table id="users-list" ref="usersList" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>Administrador</th>
                    <th>Somente Leitura</th>
                    <th>Ativo</th>
                    <th>Empresa</th>
                    <th>Criado em</th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <user-tile v-for="( user, index ) in users" :user="user" :key="index" />
                </tbody>
              </table>
            </div>
          </div>
          <pagination 
            :current="page"
            :perPage="perPage"
            :onChangePage="changeToPage"
            :totalPages="lastPage"
            :totalValues="total"
          /> 
        </div>
      </div>
    </div>
  </div>

  <user-modal />
  
</template>

<script>
import UserTile from "./components/UserTile"
import UserModal from "./components/UserModal"
import { mapState } from 'vuex'
import Pagination from "../components/Pagination.vue";
import list_mixin from "../mixins/list_mixin";

export default {
  name: "Users",

  components: {
    UserTile,
    UserModal,
    Pagination
  },

  mixins: [list_mixin('users')],

  computed: {
    ...mapState('users', {
      users: state => state.users,
    }),
  },

  async created() {
    try {
      await this.$store.dispatch("users/getUsers");
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
<style>
td {
  padding: 12px 24px !important;
}
</style>
