<template>
  <div class="modal fade " id="audit_modal" tabindex="-1">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body" v-if="audit">
          <div class="container-fluid">
            <span><strong>Usuário:</strong> {{ audit.user_name }}</span><br>
            <span><strong>Modulo:</strong> {{ audit.model_name }}</span><br>
            <span><strong>Ação:</strong> {{ audit.action_name }}</span><br>
            <span><strong>Data:</strong> {{ date }}</span><br>
            <br>

            <div class="row">
              <div class="col-sm">
                <h4>Antes</h4>

                <p v-if="!audit.old_values">Vazio</p>

                <div v-for="(value, key) in audit.old_values" :key="key">
                  <span><strong>{{ key }}:</strong> {{ value }}</span><br>
                </div>
              </div>
              <div class="col-sm">
                <h4>Depois</h4>

                <p v-if="!audit.new_values">Vazio</p>

                <div v-for="(value, key) in audit.new_values" :key="key">
                  <span><strong>{{ key }}:</strong> {{ value }}</span><br>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="closeModal">Fechar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import moment from "moment/moment";
import { mapState } from "vuex";

export default {
  name: "AuditModal",
  mounted() {
    this.audit_modal = new bootstrap.Modal('#audit_modal', {
      backdrop: 'static'
    });
  },
  computed: {
    ...mapState('audit', {
      open: state => state.modal.open,
      audit: state => state.modal.audit,
    }),
    date() {
      return moment(this.audit.created_at).format("DD/MM/YYYY HH:mm");
    }
  },
  watch: {
    open(newOpen) {
      if (newOpen) this.openAuditModal();
      else this.closeAuditModal();
    },
  },
  methods: {
    closeModal() {
      this.$store.dispatch('audit/closeModal');
    },
    openAuditModal() {
      this.audit_modal.show()
    },
    closeAuditModal() {
      this.audit_modal.hide()
    }
  },
}
</script>