<template>
    <tr @click="startEditing">
        <td>{{ name }}</td>
        <td>{{ active }}</td>
        <td>{{ created_at }}</td>
    </tr>
</template>

<script>
import moment from 'moment';

export default {
    props: ['company'],
    data: (instance) => ({
        name: instance.company.name,
        active: instance.company.active ? "Sim" : "Não",
        created_at: moment(instance.company.created_at).format("DD/MM/YYYY HH:mm")
    }),
    methods: {
        startEditing() {
            this.$store.dispatch('companies/startEditing', this.company)
        }
    }
}
</script>