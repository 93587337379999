import vouchersService from "../services/vouchers.service"
import { parseFormError } from "../services/utils";

const initialState = {
  vouchers: [],
  loading: false,
  page: 1,
  lastPage: 1,
  perPage: 1,
  total: 0,
  form: {
    open: false,
    voucher: null,
    error:null,
  }
}

export const vouchers = {
  namespaced: true,
  state: initialState,
  actions: {
    async getVouchers({ commit, state }) {
      commit("SET_LOADING", true);
      const response = await vouchersService.getVouchers(state.page);
      commit("SET_VOUCHERS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);
    },
    changeToPage({ commit, dispatch, state }, page) {
      if (page >= 1 && page <= state.lastPage) {
        commit("SET_PAGE", page);
        dispatch("getVouchers");
      }
    },
    startAdding({ commit }) {
      const value = { open: true, voucher: null };
      commit("SET_FORM", value);
    },
    async addVoucher({ commit, dispatch }, voucher) {
      try {
        await vouchersService.addVoucher(voucher);
        dispatch("stopEditing");
        dispatch("getVouchers");
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error))
      }
    },
    async startEditing({ commit }, voucher) {
      const value = { open: true, voucher: voucher };
      commit("SET_FORM", value);
    },
    async updateVoucher({ commit, dispatch }, voucher) {
      try {
        await vouchersService.updateVoucher(voucher);
        dispatch("stopEditing");
        dispatch("getVouchers");
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error))
      }
    },
    async stopEditing({ commit }) {
      const value = { open: false, voucher: null };
      commit("SET_FORM", value);
    },
  },
  mutations: {
    SET_VOUCHERS(state, vouchers) {
      state.vouchers = vouchers
      state.loading = false
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    SET_TOTAL(state, total) {
      state.total = total
    },
    SET_PER_PAGE(state, per_page) {
      state.perPage = per_page
    },
    SET_LAST_PAGE(state, last_page) {
      state.lastPage = last_page
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_FORM(state, { open, voucher }) {
      state.form.open = open;
      state.form.voucher = voucher;
    },
    SET_FORM_ERROR(state,error){
      state.form.error = error
    },   
    resetState(state) {
      state.vouchers = [];
      state.loading = false;
      state.form = {
        open: false,
        voucher: null,
      };
    }
  },
}