import auditService from "../services/audit.service"

const initialState = {
  audits: [],
  loading: false,
  page: 1,
  lastPage: 1,
  perPage: 1,
  total: 0,
  modal: {
    open: false,
    audit: null
  }
}

export const audit = {
  namespaced: true,
  state: initialState,
  actions: {
    async getAudits({ commit, state }) {
      commit("SET_LOADING", true);
      const response = await auditService.getAudits(state.page);
      commit("SET_AUDIT", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);
    },
    changeToPage({ commit, dispatch, state }, page) {
      if (page >= 1 && page <= state.lastPage) {
        commit("SET_PAGE", page);
        dispatch("getAudits");
      }
    },
    showModal({ commit }, audit) {
      const value = { open: true, audit: audit };
      commit("SET_MODAL", value);
    },
    closeModal({commit}) {
      const value = { open: false, audit: null };
      commit("SET_MODAL", value);
    }
  },
  mutations: {
    SET_AUDIT(state, audits) {
      state.audits = audits
      state.loading = false
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    SET_TOTAL(state, total) {
      state.total = total
    },
    SET_PER_PAGE(state, per_page) {
      state.perPage = per_page
    },
    SET_LAST_PAGE(state, last_page) {
      state.lastPage = last_page
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_MODAL(state, { open, audit }) {
      state.modal.open = open;
      state.modal.audit = audit;
    },
    resetState(state) {
      state.audits = [];
      state.loading = false;
      state.form = {
        open: false,
        audit: null,
      };
    }
  }
}