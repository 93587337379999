import authService from "./auth.service";     

export default function authHeader() {
  let user = authService.getToken();

  if (user) {
    return {
      Authorization: "Bearer " + user,
    };
  } else {
    return {};
  }
}