<template>
    <tr @click="showModal">
        <td>{{ user }}</td>
        <td>{{ model }}</td>
        <td>{{ action }}</td>
        <td>{{ created_at }}</td>
    </tr>
</template>

<script>
import moment from 'moment';

export default {
    name: "AuditTile",
    props: ['audit'],
    data: (instance) => ({
        user: instance.audit.user_name,
        model: instance.audit.model_name,
        action: instance.audit.action_name,
        created_at: moment(instance.audit.created_at).format("DD/MM/YYYY HH:mm")
    }),
    methods: {
        showModal() {
            this.$store.dispatch('audit/showModal', this.audit)
        },
    }
}
</script>