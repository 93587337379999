import endUserPlansService from "../services/endUserPlans.service"
import { parseFormError } from "../services/utils";

const initialState = {
  endUserPlans: [],
  loading: false,
  form: {
    open: false,
    endUserPlan: null,
    error: null,
  }
}

export const endUserPlans = {
  namespaced: true,
  state: initialState,
  actions: {
    async getEndUserPlans({ commit }) {
      commit("SET_LOADING", true);
      const response = await endUserPlansService.getEndUserPlans();
      commit("SET_USERS", response);
    },
    startAdding({ commit }) {
      const value = { open: true, endUserPlan: null };
      commit("SET_FORM", value);
    },
    async addEndUserPlan({ commit,dispatch }, endUserPlan) {
      
      console.log(endUserPlan);
      try{
      let endUserId =  endUserPlan.endUserId;
      await endUserPlansService.addEndUserPlan(endUserId, endUserPlan);
      dispatch("stopEditing");
      }catch(error){
        commit("SET_FROM_ERROR", parseFormError(error));
      }
    },
    async startEditing({ commit }, endUserPlan) {
      const value = { open: true, endUserPlan: endUserPlan };
      commit("SET_FORM", value);
    },
    async updateEndUserPlan({ commit, dispatch }, endUserPlan) {
      try{
      await endUserPlansService.updateEndUserPlan(endUserPlan);
      dispatch("stopEditing");
      } catch(error){
        commit("SET_FORM_ERROR", parseFormError(error));
      }
    },
    async stopEditing({ commit }) {
      const value = { open: false, endUserPlan: null };
      commit("SET_FORM", value);
    },
  },
  mutations: {
    SET_USERS(state, endUserPlans) {
      state.endUserPlans = endUserPlans
      state.loading = false
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_FORM(state, { open, endUserPlan }) {
      state.form.open = open;
      state.form.endUserPlan = endUserPlan;
    },
    SET_FORM_ERROR(state, error){
      state.form.error = error
    },
    resetState(state) {
      state.endUserPlans = [];
      state.loading = false;
      state.form = {
        open: false,
        endUserPlan: null,
      };
    }
  },
  getters: {
    total(state) {
      return state.endUserPlans.length;
    }
  }
}