const PeriodTypes = {
    DAILY: "daily",
    WEEKLY: "weekly",
    BIWEEKLY: "biweekly",
    MOTHLY: "mothly",
    BIMONTHLY: "bimonthly",
    QUARTELY: "quartely",
    YEARLY: "yearly"
}

export default PeriodTypes;

export const PeriodTypesOptions = [
    { value: "daily", label: "Diariamente" },
    { value: "weekly", label: "Semanalmente" },
    { value: "biweekly", label: "Quinzenal" },
    { value: "mothly", label: "Mensal" },
    { value: "bimonthly", label: "Bimestral" },
    { value: "quartely", label: "Quadrimestral" },
    { value: "yearly", label: "Anual" },
]