<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Usuários</h5>
              </div>
              <div v-if="!readOnly" class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <a @click="startAdding" class="mb-0 btn bg-gradient-success btn-sm">
                    +&nbsp; Novo Usuário
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="spinner-border" role="status" v-if="loading">
            <span class="sr-only">Carregando...</span>
          </div>
          <div class="px-0 pb-0 card-body" v-else>
            <div class="table-responsive">
              <table id="endUsers-list" ref="endUsersList" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>Ativo</th>
                    <th>Empresa</th>
                    <th>Criado em</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <endUser-tile v-for="( endUser, index ) in endUsers" :endUser="endUser" :key="index" />
                </tbody>
              </table>
            </div>
          </div>
          <pagination 
            :current="page"
            :perPage="perPage"
            :onChangePage="changeToPage"
            :totalPages="lastPage"
            :totalValues="total"
          /> 
        </div>
      </div>
    </div>
  </div>

  <endUser-modal />
  
</template>

<script>
import EndUserTile from "./components/EndUserTile"
import EndUserModal from "./components/EndUserModal"
import Pagination from "../components/Pagination.vue";

import list_mixin from "../mixins/list_mixin";
import { mapState } from 'vuex'

export default {
  name: "EndUsers",

  components: {
    EndUserTile,
    EndUserModal,
    Pagination
  },

  mixins: [list_mixin('endUsers')],

  computed: {
    ...mapState('endUsers', {
      endUsers: state => state.endUsers,
    }),
    ...mapState('auth', {
      readOnly: state => state.readOnly
    })
  },

  async created() {
    try {
      await this.$store.dispatch("endUsers/getEndUsers");
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
<style>
td {
  padding: 12px 24px !important;
}
</style>
