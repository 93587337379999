<template>
  <div class="modal fade" id="user_modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <form @submit.prevent="handleSubmit">
            <label for="name">Nome</label>
            <soft-model-input
              id="name"
              v-model="state.name"
              type="text"
              placeholder="Nome"
              name="name"
            />

            <label for="email">E-mail</label>
            <soft-model-input
              id="email"
              v-model="state.email"
              type="email"
              placeholder="E-mail"
              name="email"
            />

            <label>Senha</label>
            <soft-model-input
              id="password"
              v-model="state.password"
              type="password"
              placeholder="Senha"
              name="password"
            />

            <label>Empresa</label>
            <soft-select
              id="company"
              name="company"
              v-model="state.company"
              :options="companies"
              placeholder="Selecione a empresa"
            />

            <soft-switch-model 
              id="active"
              name="active"
              v-model="state.active"
            >
              Ativo
            </soft-switch-model>

            <soft-switch-model 
              id="is_admin"
              name="is_admin"
              v-model="state.is_admin"
            >
              É administrador?
            </soft-switch-model>
            
            <soft-switch-model 
              id="read_only"
              name="read_only"
              v-model="state.read_only"
              v-if="!state.is_admin"
            >
              Somente leitura?
            </soft-switch-model>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="stopEditing">Cancelar</button>
          <button
            type="button"
            @click="handleSubmit"
            class="btn btn-success"
            :disabled="loading ? true : false"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import SoftModelInput from "@/components/SoftModelInput.vue";
import SoftSwitchModel from "../../components/SoftSwitchModel.vue";
import SoftSelect from "../../components/SoftSelect";
import formModalMixin from "../../mixins/form-modal-mixin";
import * as yup from "yup";
import { mapState } from "vuex";  
import showSwal from "../../mixins/showSwal";

const getState = (user = null) => ({
  id: user?.id,
  name: user?.name ?? '',
  email: user?.email ?? '',
  company: user?.company ?? '',
  active: user?.active ?? true,
  is_admin: user?.is_admin ?? false,
  read_only: user?.read_only ?? false
})

export default {
  name: "UserModal",
  mixins: [formModalMixin('users')],
  components: {
    SoftModelInput,
    SoftSwitchModel,
    SoftSelect,
  },
  mounted() {
    this.user_modal = new bootstrap.Modal('#user_modal', {
      backdrop: 'static'
    });
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string().required(),
      email: yup
        .string()
        .required("Email is required!")
        .email("Must be a valid email! ex.: name@company.domain"),
      password: yup.string(),
      active: yup.boolean().required(),
      is_admin: yup.boolean().required(),
    });

    return {
      schema,
      loading: false,
      state: getState()
    }
  },
  computed: {
    ...mapState('users', {
      open: state => state.form.open,
      user: state => state.form.user,
      title: state => state.form.user?.name ?? "Novo Usuário",
    }),
    companies() {
      return this.$store.state.companies.options;
    }
  },
  async created() {
    try {
      await this.$store.dispatch("companies/getOptions");
    } catch (error) {
      console.error(error);
    }
  },
  watch: {
    open (newOpen) {
      if (newOpen) this.openUserModal();
      else this.closeUserModal();
    },
    user (newUser) {
      this.state = getState(newUser);
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      try {
        if (this.user) 
        await this.$store.dispatch('users/updateUser', this.state);
        else 
        await this.$store.dispatch('users/addUser', this.state);
          if(this.error == null){
            showSwal.methods.showSwal({
              type: "success",
              message: "Usuário criado com sucesso!",
              timer: 5000
            })
          }
        this.loading = false
        this.state = getState();
      } catch (error) {
        this.loading = false;
      }
    },
    stopEditing() {
      this.$store.dispatch('users/stopEditing');
    },
    openUserModal() {
      this.user_modal.show()
    },
    closeUserModal() {
      this.user_modal.hide()
    }
  },
}
</script>