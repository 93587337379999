<template>
  <div class="modal fade" id="company_modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <form
            id="myForm"
            @submit.prevent="handleSubmit"
          >
            <label for="name">Nome</label>
            <soft-model-input
              id="name"
              v-model="state.name"
              type="text"
              placeholder="Nome"
              name="name"
            />

            <soft-switch-model 
              id="active"
              name="active"
              v-model="state.active"
            >
              Ativo
            </soft-switch-model>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="stopEditing">Cancelar</button>
          <button
            type="submit"
            class="btn btn-success"
            form="myForm"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import SoftModelInput from "@/components/SoftModelInput.vue";
import SoftSwitchModel from "../../components/SoftSwitchModel.vue";
import formModalMixin from "../../mixins/form-modal-mixin";
import * as yup from "yup";
import { mapState } from "vuex";
import showSwal from "../../mixins/showSwal";

const getState = (company = null) => ({
  id: company?.id,
  name: company?.name ?? '',
  active: company?.active ?? true,
});

export default {
  name: "CompanyModal",
  mixins:[formModalMixin("companies")],
  components: {
    SoftModelInput,
    SoftSwitchModel
  },
  mounted() {
    this.company_modal = new bootstrap.Modal('#company_modal', {
      backdrop: 'static'
    });
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string().required(),
      active: yup.boolean().required(),
    });

    return {
      schema,
      loading: false,
      state: getState()
    }
  },
  computed: {
    ...mapState('companies', {
      open: state => state.form.open,
      company: state => state.form.company,
      title: state => state.form.company?.name ?? "Nova Empresa",
    }),
  },
  watch: {
    open (newOpen) {
      if (newOpen) this.openCompanyModal();
      else this.closeCompanyModal();
    },
    company (newCompany) {
      this.state = getState(newCompany)
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      try {
        if (this.company) 
        await this.$store.dispatch('companies/updateCompany', this.state);
        else 
        await this.$store.dispatch('companies/addCompany', this.state);
            if(this.error == null){
              showSwal.methods.showSwal({
                type:"success",
                message:"Empresa Criada Com Sucesso!",
                timer: 5000
              })
            }
         this.loading = false;
        this.state = getState();
      } catch (error) {
        this.loading = false;
      }
    },
    stopEditing() {
      this.$store.dispatch('companies/stopEditing');
    },
    openCompanyModal() {
      this.company_modal.show()
    },
    closeCompanyModal() {
      this.company_modal.hide()
    }
  },
}
</script>