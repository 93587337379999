import endUsersService from "../services/endUsers.service"
import { parseFormError } from "../services/utils";

const initialState = {
  endUsers: [],
  loading: false,
  page: 1,
  lastPage: 1,
  perPage: 1,
  total: 0,
  form: {
    open: false,
    endUser: null,
    error: null,
  }
}

export const endUsers = {
  namespaced: true,
  state: initialState,
  actions: {
    async getEndUsers({ commit, state }) {
      commit("SET_LOADING", true);
      const response = await endUsersService.getEndUsers(state.page);
      commit("SET_USERS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);
    },
    changeToPage({ commit, dispatch, state }, page) {
      if (page >= 1 && page <= state.lastPage) {
        commit("SET_PAGE", page);
        dispatch("getEndUsers");
      }
    },
    startAdding({ commit }) {
      const value = { open: true, endUser: null };
      commit("SET_FORM", value);
    },
    async addEndUser({commit, dispatch }, endUser) {
      try{
      await endUsersService.addEndUser(endUser);
      dispatch("stopEditing");
      dispatch("getEndUsers");
      }catch(error){
        commit('SET_FORM_ERROR', parseFormError(error));
      }
    },
    async startEditing({ commit }, endUser) {
      const value = { open: true, endUser: endUser };
      commit("SET_FORM", value);
    },
    async updateEndUser({commit, dispatch }, endUser) {
      try{
      await endUsersService.updateEndUser(endUser);
      dispatch("stopEditing");
      dispatch("getEndUsers");
      }catch(error){
        commit('SET_FORM_ERROR', parseFormError(error));
      }
    },
    async stopEditing({ commit }) {
      const value = { open: false, endUser: null };
      commit("SET_FORM", value);
    },
    async sendAccessEmail(_, endUser) {
      await endUsersService.sendAccessEmail(endUser.id)
    }
  },
  mutations: {
    SET_USERS(state, endUsers) {
      state.endUsers = endUsers
      state.loading = false
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    SET_TOTAL(state, total) {
      state.total = total
    },
    SET_PER_PAGE(state, per_page) {
      state.perPage = per_page
    },
    SET_LAST_PAGE(state, last_page) {
      state.lastPage = last_page
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_FORM(state, { open, endUser }) {
      state.form.open = open;
      state.form.endUser = endUser;
    },
    SET_FORM_ERROR(state, error){
      state.form.error = error
    },
    resetState(state) {
      state.endUsers = [];
      state.loading = false;
      state.form = {
        open: false,
        endUser: null,
      };
    }
  },
}