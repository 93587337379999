import companiesService from "../services/companies.service"
import { parseFormError } from "../services/utils";
const initialState = {
  companies: [],
  loading: false,
  options: [],
  page: 1,
  lastPage: 1,
  perPage: 1,
  total: 0,
  form: {
    
    open: false,
    company: null,
    error: null
  }
}

export const companies = {
  namespaced: true,
  state: initialState,
  actions: {
    async getCompanies({ commit, state }) {
      commit("SET_LOADING", true);
      const response = await companiesService.getCompanies(state.page);
      commit("SET_COMPANIES", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);
    },
    async getOptions({ commit }) {
      commit("SET_LOADING", true);
      const response = await companiesService.getOptions();
      commit("SET_OPTIONS", response);
    },
    changeToPage({ commit, dispatch, state }, page) {
      if (page >= 1 && page <= state.lastPage) {
        commit("SET_PAGE", page);
        dispatch("getCompanies");
      }
    },
    startAdding({ commit }) {
      const value = { open: true, company: null };
      commit("SET_FORM", value);
    },
    async addCompany({ commit, dispatch }, company) {
      try {
        await companiesService.addCompany(company);
        dispatch("stopEditing");
        dispatch("getCompanies");
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error))
      }

    },
    async startEditing({ commit }, company) {
      const value = { open: true, company: company };
      commit("SET_FORM", value);
    },
    async updateCompany({ commit, dispatch }, company) {
      try {
        await companiesService.updateCompany(company);
        dispatch("stopEditing");
        dispatch("getCompanies");
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error))
      }

    },
    async stopEditing({ commit }) {
      const value = { open: false, company: null };
      commit("SET_FORM", value);
    },
  },
  mutations: {
    SET_COMPANIES(state, companies) {
      state.companies = companies
      state.loading = false
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    SET_TOTAL(state, total) {
      state.total = total
    },
    SET_PER_PAGE(state, per_page) {
      state.perPage = per_page
    },
    SET_LAST_PAGE(state, last_page) {
      state.lastPage = last_page
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_OPTIONS(state, companies) {
      state.options = companies
      state.loading = false
    },
    SET_FORM(state, { open, company }) {
      state.form.open = open;
      state.form.company = company;
      state.form.error = null;
    },
    SET_FORM_ERROR(state,error){
      state.form.error = error
    },
    resetState(state) {
      state.companies = [];
      state.loading = false;
      state.form = {
        open: false,
        company: null,
      };
    }
  },
}