<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Auditoria</h5>
              </div>
            </div>
          </div>
          <div class="spinner-border" role="status" v-if="loading">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="px-0 pb-0 card-body" v-else>
            <div class="table-responsive">
              <table id="audits-list" ref="auditsList" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Usuário</th>
                    <th>Modulo</th>
                    <th>Ação</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <audit-tile v-for="( audit, index ) in audits" :audit="audit" :key="index" />
                </tbody>
              </table>
            </div>
          </div>
          <pagination 
            :current="page"
            :perPage="perPage"
            :onChangePage="changeToPage"
            :totalPages="lastPage"
            :totalValues="total"
          /> 
        </div>
      </div>
    </div>
  </div>

  <audit-modal />
  
</template>

<script>
import AuditTile from "./components/AuditTile"
import AuditModal from "./components/AuditModal"
import { mapState } from 'vuex'
import Pagination from "../components/Pagination.vue";
import list_mixin from "../mixins/list_mixin";

export default {
  name: "Audit",

  components: {
    AuditTile,
    AuditModal,
    Pagination
  },

  mixins: [list_mixin('audit')],

  computed: {
    ...mapState('audit', {
      audits: state => state.audits,
    }),
  },

  async created() {
    try {
      await this.$store.dispatch("audit/getAudits");
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
<style>
td {
  padding: 12px 24px !important;
}
</style>
