import api from "./axios";

export default {
    async getVouchers(page = 1) {
        const response = await api.get(`/vouchers?page=${page}`);
        return response.data;
    },
    async addVoucher(voucher) {
        const response = await api.post('/vouchers', voucher)
        return response.data;
    },
    async updateVoucher(voucher) {
        const response = await api.put(`/vouchers/${voucher.id}`, voucher)
        return response.data;
    },
}