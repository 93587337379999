import usersService from "../services/users.service"
import { parseFormError } from "../services/utils";

const initialState = {
  users: [],
  loading: false,
  page: 1,
  lastPage: 1,
  perPage: 1,
  total: 0,
  form: {
    open: false,
    user: null,
    error: null,
  }
}

export const users = {
  namespaced: true,
  state: initialState,
  actions: {
    async getUsers({ commit, state }) {
      commit("SET_LOADING", true);
      const response = await usersService.getUsers(state.page);
      commit("SET_USERS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);
    },
    changeToPage({ commit, dispatch, state }, page) {
      if (page >= 1 && page <= state.lastPage) {
        commit("SET_PAGE", page);
        dispatch("getUsers");
      }
    },
    startAdding({ commit }) {
      const value = { open: true, user: null };
      commit("SET_FORM", value);
    },
    async addUser({ dispatch, commit }, user) {
      try{
        await usersService.addUser(user);
        dispatch("stopEditing");
        dispatch("getUsers");
      } catch(error){
        commit('SET_FORM_ERROR', parseFormError(error));
      }
    },
    async startEditing({ commit }, user) {
      const value = { open: true, user: user };
      commit("SET_FORM", value);
    },
    async updateUser({ dispatch,commit }, user) {
      try{
      await usersService.updateUser(user);
      dispatch("stopEditing");
      dispatch("getUsers");
      } catch(error){
        commit('SET_FORM_ERROR', parseFormError(error));
      }
    },
    async stopEditing({ commit }) {
      const value = { open: false, user: null };
      commit("SET_FORM", value);
    },
  },
  mutations: {
    SET_USERS(state, users) {
      state.users = users
      state.loading = false
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    SET_TOTAL(state, total) {
      state.total = total
    },
    SET_PER_PAGE(state, per_page) {
      state.perPage = per_page
    },
    SET_LAST_PAGE(state, last_page) {
      state.lastPage = last_page
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_FORM(state, { open, user }) {
      state.form.open = open;
      state.form.user = user;
    },
    SET_FORM_ERROR(state, error){
      state.form.error = error
    },
    resetState(state) {
      state.users = [];
      state.loading = false;
      state.form = {
        open: false,
        user: null,
      };
    }
  },
}