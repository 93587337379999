<template>
  <div class="modal fade" id="plan_modal" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <form
            id="myForm"
            @submit.prevent="handleSubmit"
          >
            <label for="name">Nome</label>
            <soft-model-input
              id="name"
              v-model="state.name"
              type="text"
              placeholder="Nome"
              name="name"
            />
            
            <label for="periodType">Tipo de recorrência</label>
            <soft-select
              id="periodType"
              name="periodType"
              v-model="state.periodType"
              :options="PeriodTypesOptions"
              placeholder="Selecione o tipo de recorrência"
            />
            
            <label for="name">Recorrência</label>
            <soft-model-input
              id="periodUnit"
              v-model="state.periodUnit"
              type="number"
              placeholder="Recorrência"
              name="periodUnit"
            />
            
            <label for="price">Preço</label>
            <soft-model-input
              id="price"
              v-model="state.price"
              placeholder="Preço"
              name="price"
            />

            <label for="name">Data de inicio</label>
            <VueDatePicker 
              id="startDate"
              v-model="state.startDate"
              placeholder="Data de inicio"
              name="startDate"
              format="dd/MM/yyyy HH:mm"
              :model-type="modelType"
              :locale="$datePickerLocale"
            />
            
            <label for="name">Data de fim</label>
            <VueDatePicker 
              id="endDate"
              v-model="state.endDate"
              placeholder="Data de fim"
              name="endDate"
              format="dd/MM/yyyy HH:mm"
              :model-type="modelType"
              :locale="$datePickerLocale"
            />

            <soft-switch-model 
              id="active"
              name="active"
              v-model="state.active"
            >
              Ativo
            </soft-switch-model>
            
            <soft-switch-model 
              id="visible"
              name="visible"
              v-model="state.visible"
            >
              Visível
            </soft-switch-model>

            <soft-switch-model 
              id="recurrent"
              name="recurrent"
              v-model="state.recurrent"
            >
              Recorrente
            </soft-switch-model>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="stopEditing">Cancelar</button>
          <button
            type="submit"
            form="myForm"
            class="btn btn-success"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import SoftModelInput from "../../components/SoftModelInput";
import SoftSwitchModel from "../../components/SoftSwitchModel.vue";
import SoftSelect from "../../components/SoftSelect.vue";
import formModalMixin from "../../mixins/form-modal-mixin";
import * as yup from "yup";
// import moment from "moment";
import { mapState } from "vuex";
import { PeriodTypesOptions } from "../../constants/PeriodTypes";
import { floatToMoney } from "../../helpers/money";
import showSwal from "../../mixins/showSwal";

const getState = (plan = null) => ({
  id: plan?.id,
  name: plan?.name ?? '',
  periodUnit: plan?.periodUnit ?? '',
  periodType: plan?.periodType ?? '',
  price: plan?.price != null ? floatToMoney(plan.price) : '',
  startDate: plan?.startDate ?? '',
  endDate: plan?.endDate ?? '',
  active: plan?.active ?? true,
  visible: plan?.visible ?? false,
  recurrent: plan?.recurrent ?? false,
});

export default {
  name: "PlanModal",
  mixins: [formModalMixin("plans")],
   components: {
    SoftSwitchModel,
    SoftSelect,
    SoftModelInput,
  },
  mounted() {
    this.plan_modal = new bootstrap.Modal('#plan_modal', {
      backdrop: 'static'
    });
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string().required(),
      active: yup.boolean().required(),
      periodUnit: yup.number().required(),
      periodType: yup.string().required()
    });

    return {
      schema,
      loading: false,
      PeriodTypesOptions,
      state: getState(),
      modelType: "yyyy-MM-dd HH:mm:ss"
    }
  },
  computed: {
    ...mapState('plans', {
      open: state => state.form.open,
      plan: state => state.form.plan,
      title: state => state.form.plan?.name ?? "Novo Plano",
    }),
  },
  watch: {
    open (newOpen) {
      if (newOpen) this.openPlanModal();
      else this.closePlanModal();
    },
    plan (newPlan) {
      this.state = getState(newPlan);
    }
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      try {
        if (this.plan) await this.$store.dispatch('plans/updatePlan', this.state);
        else
        await this.$store.dispatch('plans/addPlan', this.state);
          if(this.error ==null){
            showSwal.methods.showSwal({
              type:"success",
              message: "Plano criado com sucesso!",
              timer: 5000
              
            })
          }
        this.loading = false;
        this.state = getState();
      } catch (error) {
        this.loading = false;
      }
    },
    stopEditing() {
      this.$store.dispatch('plans/stopEditing');
    },
    openPlanModal() {
      this.plan_modal.show()
    },
    closePlanModal() {
      this.plan_modal.hide()
    }
  },
}
</script>