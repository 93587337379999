import api from "./axios";

export default {
    async getPlans(page = 1) {
        const response = await api.get(`/plans?page=${page}`);
        return response.data;
    },
    async getOptions(isFree = false) {
        const response = await api.get(`/plans/options`, 
            { params: { free: isFree ? isFree : null } }
        );
        return response.data;
    },
    async addPlan(plan) {
        const response = await api.post('/plans', plan)

        return response.data;
    },
    async updatePlan(plan) {
        const response = await api.put(`/plans/${plan.id}`, plan)

        return response.data;
    },
}