<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0">Cupons</h5>
              </div>
              <div class="my-auto mt-4 ms-auto mt-lg-0">
                <div class="my-auto ms-auto">
                  <a @click="startAdding" class="mb-0 btn bg-gradient-success btn-sm">
                    +&nbsp; Novo Cupom
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="spinner-border" role="status" v-if="loading">
            <span class="sr-only">Loading...</span>
          </div>
          <div class="px-0 pb-0 card-body" v-else>
            <div class="table-responsive">
              <table id="vouchers-list" ref="vouchersList" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Name</th>
                    <th>Código</th>
                    <th>Plano</th>
                    <th>Uso único</th>
                    <th>Ativo</th>
                    <th>Criado em</th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <voucher-tile v-for="( voucher, index ) in vouchers" :voucher="voucher" :key="index" />
                </tbody>
              </table>
            </div>
          </div>
          <pagination 
            :current="page"
            :perPage="perPage"
            :onChangePage="changeToPage"
            :totalPages="lastPage"
            :totalValues="total"
          /> 
        </div>
      </div>
    </div>
  </div>

  <voucher-modal />
  
</template>

<script>
import VoucherTile from "./components/VoucherTile"
import VoucherModal from "./components/VoucherModal"
import { mapState } from 'vuex'
import Pagination from "../components/Pagination.vue";
import list_mixin from "../mixins/list_mixin";

export default {
  name: "Vouchers",

  components: {
    VoucherTile,
    VoucherModal,
    Pagination
  },

  mixins: [list_mixin('vouchers')],

  computed: {
    ...mapState('vouchers', {
      vouchers: state => state.vouchers,
    }),
  },

  async created() {
    try {
      await this.$store.dispatch("vouchers/getVouchers");
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
<style>
td {
  padding: 12px 24px !important;
}
</style>
