import { mapActions, mapState } from "vuex";

export default (store) => ({
    computed: {
        ...mapState(store, {
            total: state => state.total,
            page: state => state.page,
            lastPage: state => state.lastPage,
            perPage: state => state.perPage,
            loading: state => state.loading 
        })
    },
    methods: {
        ...mapActions(store, [
            'changeToPage',
            'startAdding'
        ]),
    }
})