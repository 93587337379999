<template>
    <tr @click="startEditing">
        <td>{{ name }}</td>
        <td>{{ active }}</td>
        <td>{{ visible }}</td>
        <td>{{ recurrent }}</td>
        <td>{{ created_at }}</td>
    </tr>
</template>

<script>
import moment from 'moment';

export default {
    props: ['plan'],
    data: (instance) => ({
        name: instance.plan.name,
        active: instance.plan.active ? "Sim" : "Não",
        visible: instance.plan.visible ? "Sim" : "Não",
        recurrent: instance.plan.recurrent ? "Sim" : "Não",
        created_at: moment(instance.plan.created_at).format("DD/MM/YYYY HH:mm")
    }),
    methods: {
        startEditing() {
            this.$store.dispatch('plans/startEditing', this.plan)
        }
    }
}
</script>