export default function auth({ next, store, to }) {
  if (!store.getters["auth/loggedIn"]) {
    return next({
      name: "Login",
    });
  }

  if (to.meta.isAdmin && !store.getters["auth/isAdmin"]) {
    return next({ name: "" });
  } 

  next();
}
  