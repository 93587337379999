<template>
    <tr @click="startEditing">
        <td>{{ plan }}</td>
        <td>{{ activateDate }}</td>
        <td>{{ endDate }}</td>
    </tr>
</template>

<script>
export default {
    props: ['endUserPlan'],
    data: (instance) => ({
        plan: instance.endUserPlan.plan_name,
        activateDate: instance.endUserPlan.activateDate,
        endDate: instance.endUserPlan.endDate,
    }),
    methods: {
    }
}
</script>