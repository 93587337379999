import api from "./axios";

export default {
    async getEndUserPlans(endUserId) {
        const response = await api.get(`/endUsers/${endUserId}/plans`);
        return response.data;
    },
    async addEndUserPlan(endUserId, endUserPlan) {
        const response = await api.post(`/endUsers/${endUserId}/plans`, endUserPlan)

        return response.data;
    },
    async updateEndUserPlan(endUserId, endUserPlan) {
        const response = await api.put(`/endUsers/${endUserId}/plans/${endUserPlan.id}`, endUserPlan)

        return response.data;
    },
}