<template>
  <div class="modal fade" :id="modalId" tabindex="-1">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">Adicionar Plano</h5>
        </div>
        <div class="modal-body">
          <form>
            <soft-select
              id="idPlan"
              name="idPlan"
              v-model="state.idPlan"
              :options="plans"
              placeholder="Selecione o plano"
            />
            
            <label for="name">Data de inicio</label>
            <VueDatePicker 
              id="activateDate"
              v-model="state.activateDate"
              placeholder="Data de inicio"
              name="activateDate"
              format="dd/MM/yyyy HH:mm"
              model-type="yyyy-MM-dd HH:mm"
              :locale="$datePickerLocale"  
            />
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="onClose">Cancelar</button>
          <button
            type="button"
            @click="handleSubmit"
            class="btn btn-success"
            :disabled="loading ? true : false"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import SoftSelect from "../../components/SoftSelect";

export default {
  name: "AddEndUserPlanModal",
  props: ['open', 'endUserId', 'onClose'],
  components: {
    SoftSelect,
  },
  mounted() {
    this.endUser_modal = new bootstrap.Modal(`#${this.modalId}`, {
      backdrop: 'static'
    });
  },
  data() {
    return {
      modalId: `addEndUser_modal_${this.endUserId}`,
      loading: false,
      state: {
        endUserId: this.endUserId,
        idPlan: '',
        activateDate: '',
      },
    }
  },
  computed: {
    plans() {
      return this.$store.state.plans.freeOptions;
    }
  },
  async created() {
    try {
      let loading = this.$store.state.plans.loading;
      if (!loading) {
        await this.$store.dispatch("plans/getFreeOptions");
      }
    } catch (error) {
      console.error(error);
    }
  },
  watch: {
    open (newOpen) {
      this.resetState();
      if (newOpen) this.openEndUserModal();
      else this.closeEndUserModal();
    },
  },
  methods: {
    resetState() {
      this.state = {
        endUserId: this.endUserId,
        activateDate: '',
        idPlan: '',
      }
    },
    async handleSubmit() {
      this.loading = true
      try {
        if (this.endUser) await this.$store.dispatch('endUserPlans/updateEndUserPlan', this.state);
        else await this.$store.dispatch('endUserPlans/addEndUserPlan', this.state);
        this.loading = false
      } catch (error) {
        this.loading = false;
      }
      this.onClose(true);
    },
    stopEditing() {
      this.$store.dispatch('endUsers/stopEditing');
    },
    openEndUserModal() {
      this.endUser_modal.show()
    },
    closeEndUserModal() {
      this.endUser_modal.hide()
    }
  },
}
</script>