import api from "./axios";

export default {
    async getOauthClients(page = 1) {
        const response = await api.get(`/oauth/clients?page=${page}`);
        return response.data;
    },
    async addOauthClient(oauthClient) {
        const response = await api.post('/oauth/clients', oauthClient)

        return response.data;
    },
    async updateOauthClient(oauthClient) {
        const response = await api.put(`/oauth/clients/${oauthClient.id}`, oauthClient)

        return response.data;
    },
}