import api from "./axios";

export default {
  async sendEmail(email) {
    await api.post("/password-forgot", {
      email: email,
      redirect_url: process.env.VUE_APP_BASE_URL + "resetpassword/",
    });
  },

  async resetPassword(newUser) {
    await api.post("/password-reset", newUser);
  },
};
