import api from "./axios";

export default {
    async getUsers(page = 1) {
        const response = await api.get(`/users?page=${page}`);
        return response.data;
    },
    async addUser(user) {
        const response = await api.post('/users', user)

        return response.data;
    },
    async updateUser(user) {
        const response = await api.put(`/users/${user.id}`, user)

        return response.data;
    },
}