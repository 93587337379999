import plansService from "../services/plans.service"
import { parseFormError } from "../services/utils";
const initialState = {
  plans: [],
  loading: false,
  options: [],
  freeOptions: [],
  page: 1,
  lastPage: 1,
  perPage: 1,
  total: 0,
  form: {
    open: false,
    plan: null,
    error: null,
  }
}

export const plans = {
  namespaced: true,
  state: initialState,
  actions: {
    async getPlans({ commit, state }) {
      commit("SET_LOADING", true);
      const response = await plansService.getPlans(state.page);
      commit("SET_PLANS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);
    },
    async getOptions({ commit }) {
      commit("SET_LOADING", true);
      const response = await plansService.getOptions();
      commit("SET_OPTIONS", response);
    },
    async getFreeOptions({ commit }) {
      commit("SET_LOADING", true);
      const response = await plansService.getOptions(true);
      commit("SET_FREE_OPTIONS", response);
    },
    changeToPage({ commit, dispatch, state }, page) {
      if (page >= 1 && page <= state.lastPage) {
        commit("SET_PAGE", page);
        dispatch("getPlans");
      }
    },
    startAdding({ commit }) {
      const value = { open: true, plan: null };
      commit("SET_FORM", value);
    },
    async addPlan({ commit, dispatch }, plan) {
      try{
      await plansService.addPlan(plan);
      dispatch("stopEditing");
      dispatch("getPlans");
      }catch(error){
        commit("SET_FORM_ERROR", parseFormError(error))
      }
    },
    async startEditing({ commit }, plan) {
      const value = { open: true, plan: plan };
      commit("SET_FORM", value);
    },
    async updatePlan({ commit, dispatch }, plan) {
      try{
      await plansService.updatePlan(plan);
      dispatch("stopEditing");
      dispatch("getPlans");
      }catch(error){
        commit("PARSE_FORM_ERROR", parseFormError(error) )

      }
    },
    async stopEditing({ commit }) {
      const value = { open: false, plan: null };
      commit("SET_FORM", value);
    },
  },
  mutations: {
    SET_PLANS(state, plans) {
      state.plans = plans
      state.loading = false
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    SET_TOTAL(state, total) {
      state.total = total
    },
    SET_PER_PAGE(state, per_page) {
      state.perPage = per_page
    },
    SET_LAST_PAGE(state, last_page) {
      state.lastPage = last_page
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_OPTIONS(state, plans) {
      state.options = plans
      state.loading = false
    },
    SET_FREE_OPTIONS(state, plans) {
      state.freeOptions = plans
      state.loading = false
    },
    SET_FORM(state, { open, plan }) {
      state.form.open = open;
      state.form.plan = plan;
    },
    SET_FORM_ERROR(state, error){
      state.form.error = error
    },
    resetState(state) {
      state.plans = [];
      state.loading = false;
      state.form = {
        open: false,
        plan: null,
      };
    }
  },
}