import authService from "../services/auth.service";

const initialState = authService.parseToken();

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    async login({ commit }, userData) {
      const response = await authService.login(userData);
      commit("logMeIn", response);
    },

    async logout({ commit, dispatch, state }) {
      try {
        if (state.loggedIn) await authService.logout();
      } catch (err) {
        console.error(err);
      } finally {
        commit("logMeOut");
        dispatch("resetState", null, { root: true })
      }
    },

    async register({ commit }, userData) {
      await authService.register(userData);
      commit("logMeIn");
    },
  },
  mutations: {
    logMeIn(state, response) {
      state.loggedIn = true;
      state.isAdmin = response.isAdmin;
      state.readOnly = response.readOnly;
    },
    logMeOut(state) {
      state.loggedIn = false;
      state.isAdmin = false;
    },
  },

  getters: {
    loggedIn(state) {
      return state.loggedIn;
    },
    isAdmin(state) {
      return state.isAdmin;
    }
  },
};